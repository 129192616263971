import { PlatformAPI } from '@om1/platform-api'
import { ResponseTypes, createResponse } from '@om1/platform-utils'
import { hcpEndpoints } from '../../api/hcp-endpoints'
import { Org } from '../state'

export const SUCCESS = 'success'
export const UNAUTHORIZED = 'unauthorized'

const success = (response: GetOrganizationsResponse) => createResponse(SUCCESS, { response: response })

const unauthorized = (response: string) => createResponse(UNAUTHORIZED, { response: response })

export type GetOrganizationsResponse = {
    orgs: Org[]
}

export const Responses = {
    success,
    unauthorized
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Responses = ResponseTypes<typeof Responses>

export function create(platformApi: PlatformAPI) {
    return async function service(token: string | undefined) {
        const [response, status] = await platformApi(hcpEndpoints.organizations, {}, 'GET', token)
        if (status === 200 && response) {
            return success(JSON.parse(response))
        } else if (status === 401 || status === 403) {
            return unauthorized(response)
        }
    }
}
