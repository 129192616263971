import { call } from 'redux-saga/effects'

type SagaFunction = (...args: any[]) => Generator<any, any, any>

const RESTART = '@@saga/RESTART'
const FAIL = '@@saga/FAIL'

const warn = (disableWarnings: boolean, warning: string) => {
    if (!disableWarnings) {
        // eslint-disable-next-line no-console
        console.warn(warning)
    }
}

interface Options {
    defaultBehavior?: string
    disableWarnings?: boolean
    maxAttempts?: number
    onEachError?: (getNextAction: (action: string) => void, error: Error, sagaName: string, attempts: number) => Generator<any, any, any>
    onFail?: (error: any, sagaName: string, attempts: number) => void | Generator<any, any, any>
}

const keepAlive = (
    saga: SagaFunction,
    { defaultBehavior = RESTART, disableWarnings = false, maxAttempts = 3, onEachError, onFail }: Options = {}
) => {
    let attempts = 0
    let lastError: Error | null = null

    return function* restart(...args: any[]) {
        while (attempts < maxAttempts) {
            try {
                yield call(saga, ...args)
            } catch (error: any) {
                lastError = error
                let shouldStop = false
                if (typeof onEachError === 'function') {
                    let nextAction: string | undefined
                    const getNextAction = (action: string) => {
                        nextAction = action
                    }
                    yield call(onEachError, getNextAction, error, saga.name, attempts)
                    const result = nextAction || defaultBehavior
                    shouldStop = result === FAIL
                }
                if (shouldStop) {
                    break
                }
                attempts += 1
                warn(disableWarnings, `Restarting ${saga.name} because of error`)
            }
        }
        if (typeof onFail === 'function') {
            yield call(onFail, lastError, saga.name, attempts)
        } else if (!disableWarnings) {
            warn(disableWarnings, `Saga ${saga.name} failed after ${attempts}/${maxAttempts} attempts without any onFail handler`)
        }
    }
}

export default keepAlive
