import { SweetPath } from 'sweet-path'

export type Endpoints = {
    [key in string]: SweetPath<string>
}

export const hcpEndpoints: Endpoints = {
    siteLevelScores: new SweetPath('/api/organizations/:organizationId/siteLevelScores/'),
    patientsExperiencingRelapseRate: new SweetPath(
        '/api/organizations/:organizationId/site_level_benchmark_charts/patients_experiencing_relapse_chart/?daterange=:dateRange'
    ),
    patientsTreatedWithDmt: new SweetPath(
        '/api/organizations/:organizationId/site_level_benchmark_charts/patients_treated_with_dmt_rate_chart/?daterange=:dateRange'
    ),
    patientsExperiencingFalls: new SweetPath(
        '/api/organizations/:organizationId/site_level_benchmark_charts/patients_experiencing_falls_rate_chart/?daterange=:dateRange'
    ),
    enrollmentChart: new SweetPath('/api/organizations/:organizationId/site_level_benchmark_charts/enrollment_chart/?daterange=:dateRange'),
    averagePROScores: new SweetPath(
        '/api/organizations/:organizationId/site_level_benchmark_charts/average_pro_scores/?daterange=:dateRange&pro=:pro'
    ),
    organizations: new SweetPath('/api/site_level_benchmark_charts/organizations/'),
    dmtBreakdownByRoute: new SweetPath('/api/organizations/:organizationId/site_level_benchmark_charts/dmt_breakdown_by_route/'),
    dmtBreakdownByMedication: new SweetPath('/api/organizations/:organizationId/site_level_benchmark_charts/dmt_breakdown_by_medication/'),
    insurance: new SweetPath('/api/organizations/:organizationId/site_level_benchmark_charts/patient_demographics_insurance/'),
    employment: new SweetPath('/api/organizations/:organizationId/site_level_benchmark_charts/patient_demographics_employment/'),
    relapseHistoryAndRate: new SweetPath('/api/organizations/:organizationId/site_level_benchmark_charts/relapse_history_and_rate/'),
    hospitalizations: new SweetPath('/api/organizations/:organizationId/site_level_benchmark_charts/hospitalization_rate/?daterange=:dateRange'),
    erUtilizationsRate: new SweetPath(
        '/api/organizations/:organizationId/site_level_benchmark_charts/emergency_urgentcare_visit_rate/?daterange=:dateRange'
    ),
    race: new SweetPath('/api/organizations/:organizationId/site_level_benchmark_charts/patient_demographics_race/'),
    ethnicity: new SweetPath('/api/organizations/:organizationId/site_level_benchmark_charts/patient_demographics_ethnicity/'),
    coPatientPercent: new SweetPath(
        '/api/organizations/:organizationId/site_level_benchmark_charts/clinical_outcomes_patient_percentage/?assessment=:assessment'
    ),
    coMeanChange: new SweetPath(
        '/api/organizations/:organizationId/site_level_benchmark_charts/clinical_outcomes_mean_change/?assessment=:assessment'
    ),
    ptBaselineDemographics: new SweetPath('/api/organizations/:organizationId/site_level_benchmark_charts/patient_demographics_at_baseline/'),
    msDuration: new SweetPath('/api/organizations/:organizationId/site_level_benchmark_charts/patient_demographics_ms_duration/'),
    meanAgeOverTime: new SweetPath('/api/organizations/:organizationId/site_level_benchmark_charts/pt_demo_mean_age_over_time/?daterange=:dateRange'),
    percentFemaleOverTime: new SweetPath(
        '/api/organizations/:organizationId/site_level_benchmark_charts/pt_demo_percent_female_over_time/?daterange=:dateRange'
    ),
    percentNonWhiteOverTime: new SweetPath(
        '/api/organizations/:organizationId/site_level_benchmark_charts/pt_demo_percent_non_white_over_time/?daterange=:dateRange'
    ),
    percentRelapseRemittingOverTime: new SweetPath(
        '/api/organizations/:organizationId/site_level_benchmark_charts/pt_demo_percent_relapse_remitting_over_time/?daterange=:dateRange'
    ),
    percentCommercialInsuranceOverTime: new SweetPath(
        '/api/organizations/:organizationId/site_level_benchmark_charts/pt_demo_percent_commercial_insurance_over_time/?daterange=:dateRange'
    ),
    percentReceivingMriAnnually: new SweetPath(
        '/api/organizations/:organizationId/site_level_benchmark_charts/pt_demo_percent_receiving_mri_annually/?daterange=:dateRange'
    )
}
